

const createMemberInfo = (name, pos, img, desc, history) => ({
  name,
  pos,
  img,
  desc,
  history,
});

export default createMemberInfo;