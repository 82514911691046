import MemberImgAugustin from '../assets/images/img-member-augustin.png';
import MemberImgSimon from '../assets/images/img-member-simon.png';
import MemberImgEugene from '../assets/images/img-member-eugene.png';
import MemberImgMinsung from '../assets/images/img-member-minsung.png';
import MemberImgYongchan from '../assets/images/img-member-yongchan.png';
import MemberImgJackie from '../assets/images/img-member-jackie.png';
import MemberImgHugh from '../assets/images/img-member-hugh.png';

import createMemberInfo from './MemberInfo';

const jackie = createMemberInfo(
  "Jackie", "Co-Founder", MemberImgJackie, "", [
    { aff: "Westminster Funds Asset", pos: "Manager" },
    { aff: "Morrison Mahoney & Miller Law Firm", pos: "N.Y." },
    { aff: "Arthur Andersen", pos: "Financial Consultant" },
    { aff: "Tulane Law School", pos: "J.D. N.Y. Bar" },
    { aff: "University of Chicago", pos: "B.A." },
  ]
);

const eugene = createMemberInfo(
  "Eugene", "Co-Founder", MemberImgEugene, "", [
      { aff: "ITCEN Crypto Bank TFT", pos: "" },
      { aff: "ProBit Exchange", pos: "CFO" },
      { aff: "KPMG Samjong", pos: "CPA" },
      { aff: "Chung-Ang University", pos: "BBA" },
      { aff: "KAIST Digital Asset Specialist", pos: "" },
  ]
);

const simon = createMemberInfo(
  "Simon", "CSO", MemberImgSimon, "", [
      { aff: "ITCEN", pos: "CSO" },
      { aff: "Arthur D. Little Korea", pos: "" },
      { aff: "World Bank", pos: "Junior Researcher" },
      { aff: "Seoul National University", pos: "M.A. in Economics" },
      { aff: "Georgetown University", pos: "M.A. in Economics" },
  ]
);

const minsung = createMemberInfo(
  "Minsung", "CIO", MemberImgMinsung, "", [
      { aff: "Dongbu Life", pos: "Asset Manager" },
      { aff: "SOL PE", pos: "Fund Manager" },
      { aff: "Chung-Ang University", pos: "BBA" },
  ]
);

const cto_temp = createMemberInfo(
  "TBA", "CTO", "./images/team-no-image.png" , "To Be Announced", [
      { aff: "", pos: "" },
  ]
);

const hugh = createMemberInfo(
  "Hugh", "Front-End Engineer", "./images/team-no-image.png", "N/A", [
      { aff: "", pos: "" },
  ]
);

const augustin = createMemberInfo(
  "Augustin", "Global Partnership", MemberImgAugustin, "", [
      { aff: "ISTA", pos: "Secretary General" },
      { aff: "World Digital Art-Fair", pos: "Chairman" },
      { aff: "China Culture and Art Co., Ltd.", pos: "Vice Chairman" },
      { aff: "Kyungpook National University", pos: "M.A." },
  ]
);

const yongchan = createMemberInfo(
  "Yongchan", "Technical Advisor", MemberImgYongchan, "", [
      { aff: "ENVISAGER", pos: "Founder" },
      { aff: "GAC Solution", pos: "CEO" },
      { aff: "Seoul National University", pos: "Dept. of Nuclear Engineering" },
  ]
);

export const memberList = {
  team: [
    jackie,
    eugene,
    simon,
    minsung,
    cto_temp,
    hugh,
  ],
  advisor: [
    augustin,
    yongchan,
  ]  
}